import React from "react";
import {NovaConta} from "../dto/NovaConta";
import "../styles/CriarConta.css"
import {Controller, useForm} from "react-hook-form";
import {Button, FormControl, FormHelperText, TextField} from "@mui/material";
import {classValidatorResolver} from "@hookform/resolvers/class-validator";
import {useNavigate} from "react-router-dom";

// @ts-ignore
import arrow_back from "../img/arrow_back.svg"
import authService from "../services/authService";
import {showError} from "../helpers/toastr";
import {handleMouseOver} from "../helpers/io";


export default function CriarContaPage() {

    const resolver = classValidatorResolver(NovaConta);
    const navigate = useNavigate();


    const {
        handleSubmit,
        control,
        formState: {errors},
    } = useForm<NovaConta>({
        resolver,
    });

    const submitForm = async (data: NovaConta) => {
        try {
            await authService.createUser(data)

            navigate("/tokens")
        } catch (error) {
            if (!error.response) {
                showError(error.message)
            } else {
                showError(error.response.data.errorMessage)
            }
        }
    };

    return (
        <div className={"main"}>
            <div className={"form-login-container"}>
                <div>
                    <img src={arrow_back} alt={"Voltar"}
                         onClick={() => {
                             window.location.pathname = "/login"
                         }}
                         onMouseOver={handleMouseOver}/>

                    <h3 className={"label-login"}>Informe seus dados</h3>
                </div>

                <form onSubmit={handleSubmit(submitForm)} className={"form-conta"}>
                    <FormControl>
                        <Controller
                            name="nome"
                            control={control}
                            defaultValue=""
                            render={({field}) => (
                                <div className={"text-input-container"}>
                                    <TextField
                                        {...field}
                                        name="nome"
                                        label={"Nome"}
                                        type="text"
                                        variant="filled"
                                        autoComplete={"off"}
                                    />
                                    {errors.nome && (
                                        <FormHelperText>
                                            {errors.nome && (
                                                <p className="form-helper-text">
                                                    {errors.nome.message}
                                                </p>
                                            )}
                                        </FormHelperText>
                                    )}
                                </div>
                            )}
                        />

                        <Controller
                            name="email"
                            control={control}
                            defaultValue=""
                            render={({field}) => (
                                <div className={"text-input-container"}>
                                    <TextField
                                        {...field}
                                        name="email"
                                        label={"Email"}
                                        type="text"
                                        variant="filled"
                                        autoComplete={"off"}
                                    />
                                    {errors.email && (
                                        <FormHelperText>
                                            {errors.email && (
                                                <p className="form-helper-text">
                                                    {errors.email.message}
                                                </p>
                                            )}
                                        </FormHelperText>
                                    )}
                                </div>
                            )}
                        />

                        <Controller
                            name="usuario"
                            control={control}
                            defaultValue=""
                            render={({field}) => (
                                <div className={"text-input-container"}>
                                    <TextField
                                        {...field}
                                        name="usuario"
                                        label={"Usuario"}
                                        type="text"
                                        variant="filled"
                                        autoComplete={"off"}
                                    />
                                    {errors.usuario && (
                                        <FormHelperText>
                                            {errors.usuario && (
                                                <p className="form-helper-text">
                                                    {errors.usuario.message}
                                                </p>
                                            )}
                                        </FormHelperText>
                                    )}
                                </div>
                            )}
                        />

                        <Controller
                            name="senha"
                            control={control}
                            defaultValue=""
                            render={({field}) => (
                                <div className={"text-input-container"}>
                                    <TextField
                                        {...field}
                                        name="senha"
                                        label={"Senha"}
                                        type="password"
                                        variant="filled"
                                        autoComplete={"off"}
                                    />
                                    {errors.senha && (
                                        <FormHelperText>
                                            {errors.senha && (
                                                <p className="form-helper-text">
                                                    {errors.senha.message}
                                                </p>
                                            )}
                                        </FormHelperText>
                                    )}
                                </div>
                            )}
                        />

                        <Controller
                            name="confSenha"
                            control={control}
                            defaultValue=""
                            render={({field}) => (
                                <div className={"text-input-container"}>
                                    <TextField
                                        {...field}
                                        name="confSenha"
                                        label={"Repetir Senha"}
                                        type="password"
                                        variant="filled"
                                        autoComplete={"off"}
                                    />
                                    {errors.confSenha && (
                                        <FormHelperText>
                                            {errors.confSenha && (
                                                <p className="form-helper-text">
                                                    {errors.confSenha.message}
                                                </p>
                                            )}
                                        </FormHelperText>
                                    )}
                                </div>
                            )}
                        />
                    </FormControl>

                    <Button variant="contained"
                            type={"submit"}>
                        Criar Conta
                    </Button>
                </form>
            </div>
        </div>
    )
}