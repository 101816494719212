import {Button, Card, CardActionArea, CardActions, CardContent, Typography} from "@mui/material";
import React from "react";

// @ts-ignore
import excluir from '../img/delete.svg';

// @ts-ignore
import edit from '../img/edit.svg';

export function CardAplicacao({data, onDelete, onEdit}) {
    return (
        <Card sx={{width: 300, marginLeft: "20px"}}>
            <CardContent>
                <Typography sx={{fontSize: 18}} color="text.secondary" gutterBottom>
                    # {data.id}
                </Typography>

                <Typography variant="h5" component="div">
                    {data.nome}
                </Typography>
            </CardContent>
            <CardActions sx={{justifyContent: "flex-end", marginRight: "10px", marginBottom: "10px"}}>
                <img src={excluir} alt={"Excluir"} onClick={() => onDelete(data)}/>

                <img src={edit} alt={"Editar"} onClick={() => onEdit(data)}/>
            </CardActions>
        </Card>
    )
}

export function CardAplicacaoImei({data, onClick}) {
    const app = data.aplicacao
    return (
        <Card sx={{width: 300, marginLeft: "20px"}}>
            <CardContent>
                <Typography sx={{fontSize: 18}} color="text.secondary" gutterBottom>
                    # {app.id}
                </Typography>

                <Typography variant="h5" component="div">
                    {app.nome}
                </Typography>
            </CardContent>
            <CardActions sx={{justifyContent: "flex-end", marginRight: "10px", marginBottom: "10px"}}>
                <Button size="small" onClick={() => onClick(data)}>Visualizar Imeis</Button>
            </CardActions>
        </Card>
    )
}