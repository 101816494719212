// @ts-ignore
import logo from "../img/logo_branca.png"

import "../styles/TopBar.css"
import React from "react";

export default function TopBar() {
    return (
        <div className={"container"}>
            <img src={logo} alt={"Logo Sankhya"}/>
        </div>
    )
}