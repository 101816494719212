import {BrowserRouter, Route, Routes} from "react-router-dom";
import {ThemeProvider} from "@mui/material/styles";

import LoginPage from "./pages/LoginPage";
import HomePage from "./pages/HomePage";
import CriarContaPage from "./pages/CriarContaPage";
import TopBar from "./components/TopBar";

import './App.css';
import theme from "./theme";
import React from "react";
import ProtectedRoute from "./security/ProtectedRoute";
import TokensPage from "./pages/TokensPage";
import AplicacoesPage from "./pages/AplicacoesPage";
import ImeisPage from "./pages/ImeisPage";

function App() {
    return (
        <div className={"app"}>
            <ThemeProvider theme={theme}>
                <BrowserRouter>
                    <TopBar/>

                    <Routes>
                        <Route path={"/login"} element={<LoginPage/>}/>

                        <Route path={"/criar-usuario"} element={<CriarContaPage/>}/>

                        <Route path={"/"}
                               element={
                                   <ProtectedRoute>
                                       <HomePage/>
                                   </ProtectedRoute>
                               }
                        >
                            <Route path={"aplicacoes"}
                                   element={
                                       <ProtectedRoute>
                                           <AplicacoesPage/>
                                       </ProtectedRoute>
                                   }
                            >
                            </Route>

                            <Route path={"tokens"}
                                   element={
                                       <ProtectedRoute>
                                           <TokensPage/>
                                       </ProtectedRoute>
                                   }
                            >
                            </Route>

                            <Route path={"imei"}
                                   element={
                                       <ProtectedRoute>
                                           <ImeisPage/>
                                       </ProtectedRoute>
                                   }
                            >
                            </Route>
                        </Route>
                    </Routes>
                </BrowserRouter>
            </ThemeProvider>
        </div>
    );
}


export default App;
