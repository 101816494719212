import React, {useEffect, useState} from "react";
import {
    Button,
    FormControl,
    FormHelperText, LinearProgress,
    TextField
} from "@mui/material";
import {Controller, useForm} from "react-hook-form";
import {classValidatorResolver} from "@hookform/resolvers/class-validator";

import {showError, showSuccess} from "../helpers/toastr";
import authService from "../services/authService";
import {Aplicacao} from "../dto/Aplicacao";
import {CardAplicacao} from "../components/CardAplicacao";
import aplicacaoService from "../services/aplicacaoService";
import Popup from "../components/Popup";

export default function AplicacoesPage() {

    const [isPopupOpen, setPopupOpen] = useState(false);
    const [apps, setApps] = useState([])
    const [loading, setLoading] = useState(true)

    const resolver = classValidatorResolver(Aplicacao);

    const {
        handleSubmit,
        control,
        reset: resetForm,
        formState: {errors},
    } = useForm<Aplicacao>({
        resolver: resolver,
    });

    const submitForm = async (data: Aplicacao) => {
        setLoading(true)

        aplicacaoService.saveApp(authService.getToken(), data)
            .then((data) => {
                setApps((prev) => {
                    const tokensSemItemExistente = prev.filter(item => item.id !== data.id);
                    return tokensSemItemExistente.concat(data);
                });
                closePopup()
            })
            .catch(error => {
                if (!error.response) {
                    showError(error.message)
                } else {
                    showError(error.response.data.errorMessage)
                }
            })
            .finally(() => {
                setLoading(false)
            })
    };

    const openPopup = () => {
        setPopupOpen(true);
    };

    const closePopup = () => {
        resetForm(new Aplicacao())
        setPopupOpen(false);
    };

    const editApp = (data: Aplicacao) => {
        resetForm(data)
        setPopupOpen(true)
    }

    const deleteApp = (data: Aplicacao) => {
        setLoading(true)
        aplicacaoService.deleteApp(authService.getToken(), data)
            .then(() => {
                setApps((prevTokens) => {
                    return prevTokens.filter(item => item.id !== data.id);
                });

                setLoading(false)
                showSuccess("Aplicação excluída com sucesso!")
            })
            .catch(error => {
                if (!error.response) {
                    showError(error.message)
                } else {
                    showError(error.response.data.errorMessage)
                }

                setLoading(false)
            })
    }

    useEffect(() => {
        aplicacaoService.getAll(authService.getToken())
            .then(data => {
                setApps(data)
                setLoading(false)
            })
            .catch(error => {
                if (!error.response) {
                    showError(error.message)
                } else {
                    showError(error.response.data.errorMessage)
                }

                setLoading(false)
            })
    }, [])

    return (
        <div style={{width: "100%"}}>
            {loading && <LinearProgress/>}
            <div className={"home-item"}>
                <Button variant={"contained"}
                        sx={{alignSelf: "flex-end", marginBottom: "10px", marginRight: "10px"}}
                        onClick={() => {
                            openPopup()
                        }}>
                    Nova Aplicação
                </Button>

                <div style={{display: "flex"}}>
                    {apps.map(app => (
                        <CardAplicacao data={app} onDelete={deleteApp} onEdit={editApp}/>
                    ))}
                </div>

                {isPopupOpen && (
                    <Popup width={"30%"} height={"45%"} showCloseBtn={false} onClose={undefined}>
                        <form onSubmit={handleSubmit(submitForm)}
                              style={{display: "flex", flexDirection: "column", height: "100%"}}>
                            <h3>Dados da Aplicação</h3>

                            <FormControl sx={{width: "100%", display: "flex"}}>
                                <Controller
                                    name="nome"
                                    control={control}
                                    defaultValue=""
                                    render={({field}) => (
                                        <div className={"text-input-container"}>
                                            <TextField
                                                {...field}
                                                name="nomeAplicacao"
                                                label={"Nome da Aplicação"}
                                                type="text"
                                                variant="filled"
                                                autoComplete={"off"}
                                            />
                                            {errors.nome && (
                                                <FormHelperText>
                                                    {errors.nome && (
                                                        <p className="form-helper-text">
                                                            {errors.nome.message}
                                                        </p>
                                                    )}
                                                </FormHelperText>
                                            )}
                                        </div>
                                    )}
                                />

                                <Controller
                                    name="appKey"
                                    control={control}
                                    defaultValue=""
                                    render={({field}) => (
                                        <div className={"text-input-container"}>
                                            <TextField
                                                {...field}
                                                name="appKey"
                                                label={"App Key"}
                                                type="text"
                                                variant="filled"
                                                autoComplete={"off"}
                                            />
                                            {errors.appKey && (
                                                <FormHelperText>
                                                    {errors.appKey && (
                                                        <p className="form-helper-text">
                                                            {errors.appKey.message}
                                                        </p>
                                                    )}
                                                </FormHelperText>
                                            )}
                                        </div>
                                    )}
                                />
                            </FormControl>

                            <div style={{alignSelf: "flex-end", marginTop: "auto", display: "flex"}}>
                                <Button variant={"contained"} type={"submit"}
                                        sx={{width: "100px", marginRight: "10px"}}>
                                    Salvar
                                </Button>

                                <Button variant={"contained"} color={"error"} sx={{width: "100px"}}
                                        onClick={() => {
                                            closePopup()
                                        }}>
                                    Cancelar
                                </Button>
                            </div>
                        </form>
                    </Popup>
                )}
            </div>
        </div>
    )
}