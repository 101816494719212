import {IsEmail, IsNotEmpty, MaxLength, MinLength} from "class-validator";
import {Match} from "../helpers/match.decorator";
export class NovaConta {

    @IsNotEmpty({message: "Informe o nome."})
    nome: string;

    @IsEmail({}, {message: "Email inválido."})
    email: string;

    @IsNotEmpty({message: "Informe o usuário."})
    @MinLength(6, {message: "Usuário deve conter ao menos 6 dígitos."})
    usuario: string;

    @MinLength(6, {message: "Senha deve conter ao menos 6 dígitos."})
    senha: string;

    @Match("senha", {message: "Senhas devem ser iguais."})
    confSenha: string;
}
