import React, {useEffect, useState} from "react";
import {
    Button,
    FormControl,
    FormHelperText, InputLabel, LinearProgress, MenuItem,
    Paper, Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField
} from "@mui/material";
import InputMask from 'react-input-mask';
import Popup from "../components/Popup";
import {Controller, useForm} from "react-hook-form";
import {classValidatorResolver} from "@hookform/resolvers/class-validator";
import {Token} from "../dto/Token";

// @ts-ignore
import excluir from "../img/delete.svg"
// @ts-ignore
import edit from "../img/edit.svg"
import tokenService from "../services/tokenService";
import {showError, showSuccess} from "../helpers/toastr";
import authService from "../services/authService";
import parceiroService from "../services/parceiroService";
import aplicacaoService from "../services/aplicacaoService";

export default function TokensPage() {

    const [isLoading, setLoading] = useState(true)
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [tokens, setTokens] = useState([])
    const [apps, setApps] = useState([])
    const [parceiros, setParceiros] = useState([])

    const resolverToken = classValidatorResolver(Token);

    const {
        handleSubmit,
        control,
        reset: resetForm,
        formState: {errors},
    } = useForm<Token>({
        resolver: resolverToken,
    });

    const submitFormToken = async (data: Token) => {
        setLoading(true)

        tokenService.saveToken(authService.getToken(), data)
            .then((resp) => {
                setTokens((prevTokens) => {
                    const tokensSemItemExistente = prevTokens.filter(item => item.token !== resp.token);
                    return tokensSemItemExistente.concat(resp);
                });

                setPopupOpen(false)
            })
            .catch(error => {
                if (!error.response) {
                    showError(error.message)
                } else {
                    showError(error.response.data.errorMessage)
                }
            })
            .finally(() => {
                setLoading(false)
            })
    };

    const openPopup = () => {
        resetForm(new Token())
        setPopupOpen(true);
    };

    const closePopup = () => {
        setPopupOpen(false);
    };

    const editToken = (data: Token) => {
        resetForm(data)
        setPopupOpen(true)
    }

    const deleteToken = (data: Token) => {
        tokenService.deleteToken(authService.getToken(), data)
            .then(() => {
                setTokens((prevTokens) => {
                    return prevTokens.filter(item => item.token !== data.token);
                });

                showSuccess("Token excluído com sucesso!")
            })
            .catch(error => {
                if (!error.response) {
                    showError(error.message)
                } else {
                    showError(error.response.data.errorMessage)
                }
            })
    }

    useEffect(() => {
        loadData()
    }, [])

    const loadData = async () => {
        try {
            const tokens = await tokenService.getTokens(authService.getToken());
            const apps = await aplicacaoService.getAll(authService.getToken());
            const parceiros = await parceiroService.getAll(authService.getToken())

            setTokens(tokens)
            setApps(apps)
            setParceiros(parceiros)
        } catch (error) {
            if (!error.response) {
                showError(error.message);
            } else {
                showError(error.response.data.errorMessage);
            }

        } finally {
            setLoading(false)
        }
    }

    return (
        <div style={{width: "100%"}}>
            {isLoading && <LinearProgress/>}

            <div className={"home-item"}>
                <Button variant={"contained"}
                        sx={{alignSelf: "flex-end", marginBottom: "10px", marginRight: "10px"}}
                        onClick={openPopup}>
                    Novo Token
                </Button>

                {isPopupOpen && (
                    <Popup width={"60%"} height={"80%"} showCloseBtn={false} onClose={undefined}>
                        <form onSubmit={handleSubmit(submitFormToken)}
                              style={{display: "flex", flexDirection: "column", height: "100%"}}>
                            <h3>Dados do Token</h3>

                            <FormControl sx={{width: "100%", display: "flex"}}>
                                <div className={"input-container-row"}>
                                    <Controller
                                        name="aplicacao.id"
                                        control={control}
                                        render={({field}) => (
                                            <div className={"text-input-container"}>
                                                <FormControl fullWidth variant="filled">
                                                    <InputLabel id="select-ambiente-label">Aplicação</InputLabel>

                                                    <Select
                                                        {...field}
                                                        labelId="select-app-label"
                                                        label={"Aplicação"}
                                                        id="select-app"
                                                        name={"aplicacao.id"}
                                                        sx={{width: "100%"}}>
                                                        {apps.map(app => {
                                                            return (
                                                                <MenuItem value={app.id}>{app.nome}</MenuItem>
                                                            )
                                                        })}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        )}
                                    />

                                    <Controller
                                        name="ambiente"
                                        control={control}
                                        defaultValue=""
                                        render={({field}) => (
                                            <div className={"text-input-container"}>
                                                <FormControl fullWidth variant="filled">
                                                    <InputLabel id="select-ambiente-label">Ambiente</InputLabel>

                                                    <Select
                                                        {...field}
                                                        labelId="select-ambiente-label"
                                                        label={"Ambiente"}
                                                        id="select-ambiente"
                                                        name={"ambiente"}
                                                        sx={{width: "100%"}}>
                                                        <MenuItem value={"H"}>Homologação</MenuItem>
                                                        <MenuItem value={"P"}>Produção</MenuItem>
                                                    </Select>
                                                    {errors.ambiente && (
                                                        <FormHelperText>
                                                            {errors.ambiente && (
                                                                <p className="form-helper-text">
                                                                    {errors.ambiente.message}
                                                                </p>
                                                            )}
                                                        </FormHelperText>
                                                    )}
                                                </FormControl>
                                            </div>
                                        )}
                                    />
                                </div>

                                <div className={"input-container-row"}>
                                    <Controller
                                        name="token"
                                        control={control}
                                        defaultValue=""
                                        render={({field}) => (
                                            <div className={"text-input-container"}>
                                                <TextField
                                                    {...field}
                                                    name="token"
                                                    label={"Token"}
                                                    type="text"
                                                    variant="filled"
                                                    autoComplete={"off"}
                                                />
                                                {errors.token && (
                                                    <FormHelperText>
                                                        {errors.token && (
                                                            <p className="form-helper-text">
                                                                {errors.token.message}
                                                            </p>
                                                        )}
                                                    </FormHelperText>
                                                )}
                                            </div>
                                        )}
                                    />
                                </div>

                                <div className={"input-container-row"}>

                                    <Controller
                                        name="senha"
                                        control={control}
                                        defaultValue=""
                                        render={({field}) => (
                                            <div className={"text-input-container"}>
                                                <TextField
                                                    {...field}
                                                    name="senha"
                                                    label={"Senha"}
                                                    type="password"
                                                    variant="filled"
                                                    autoComplete={"off"}
                                                />
                                                {errors.senha && (
                                                    <FormHelperText>
                                                        {errors.senha && (
                                                            <p className="form-helper-text">
                                                                {errors.senha.message}
                                                            </p>
                                                        )}
                                                    </FormHelperText>
                                                )}
                                            </div>
                                        )}/>

                                    <Controller
                                        name="confSenha"
                                        control={control}
                                        defaultValue=""
                                        render={({field}) => (
                                            <div className={"text-input-container"}>
                                                <TextField
                                                    {...field}
                                                    name="confSenha"
                                                    label={"Repetir Senha"}
                                                    type="password"
                                                    variant="filled"
                                                    autoComplete={"off"}
                                                />
                                                {errors.confSenha && (
                                                    <FormHelperText>
                                                        {errors.confSenha && (
                                                            <p className="form-helper-text">
                                                                {errors.confSenha.message}
                                                            </p>
                                                        )}
                                                    </FormHelperText>
                                                )}
                                            </div>
                                        )}
                                    />
                                </div>

                                <div className={"input-container-row"}>
                                    <Controller
                                        name="parceiro.id"
                                        control={control}
                                        render={({field}) => (
                                            <div className={"text-input-container"}>
                                                <FormControl fullWidth variant="filled">
                                                    <InputLabel id="select-ambiente-label">Parceiro</InputLabel>

                                                    <Select
                                                        {...field}
                                                        labelId="select-parceiro-label"
                                                        label={"Aplicação"}
                                                        id="select-parceiro"
                                                        name={"parceiro.id"}
                                                        sx={{width: "100%"}}>
                                                        {parceiros.map(parc => {
                                                            return (
                                                                <MenuItem value={parc.id}>{parc.nomeParceiro}</MenuItem>
                                                            )
                                                        })}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        )}
                                    />

                                    <Controller
                                        name="emailPlace"
                                        control={control}
                                        defaultValue=""
                                        render={({field}) => (
                                            <div className={"text-input-container"}>
                                                <TextField
                                                    {...field}
                                                    name="emailPlace"
                                                    label={"Email Sankhya Place"}
                                                    type="text"
                                                    variant="filled"
                                                    autoComplete={"off"}
                                                />
                                                {errors.emailPlace && (
                                                    <FormHelperText>
                                                        {errors.emailPlace && (
                                                            <p className="form-helper-text">
                                                                {errors.emailPlace.message}
                                                            </p>
                                                        )}
                                                    </FormHelperText>
                                                )}
                                            </div>
                                        )}
                                    />
                                </div>

                                <div className={"input-container-row"}>
                                    <Controller
                                        name="senhaPlace"
                                        control={control}
                                        defaultValue=""
                                        render={({field}) => (
                                            <div className={"text-input-container"}>
                                                <TextField
                                                    {...field}
                                                    name="senhaPlace"
                                                    label={"Senha Sankhya Place"}
                                                    type="password"
                                                    variant="filled"
                                                    autoComplete={"off"}
                                                />
                                                {errors.senhaPlace && (
                                                    <FormHelperText>
                                                        {errors.senhaPlace && (
                                                            <p className="form-helper-text">
                                                                {errors.senhaPlace.message}
                                                            </p>
                                                        )}
                                                    </FormHelperText>
                                                )}
                                            </div>
                                        )}
                                    />

                                    <Controller
                                        name="confSenhaPlace"
                                        control={control}
                                        defaultValue=""
                                        render={({field}) => (
                                            <div className={"text-input-container"}>
                                                <TextField
                                                    {...field}
                                                    name="confSenhaPlace"
                                                    label={"Repetir Senha"}
                                                    type="password"
                                                    variant="filled"
                                                    autoComplete={"off"}
                                                />
                                                {errors.confSenhaPlace && (
                                                    <FormHelperText>
                                                        {errors.confSenhaPlace && (
                                                            <p className="form-helper-text">
                                                                {errors.confSenhaPlace.message}
                                                            </p>
                                                        )}
                                                    </FormHelperText>
                                                )}
                                            </div>
                                        )}
                                    />
                                </div>
                            </FormControl>

                            <div style={{alignSelf: "flex-end", marginTop: "auto"}}>
                                <Button variant={"contained"} type={"submit"}
                                        sx={{width: "100px", marginRight: "10px"}}>
                                    Salvar
                                </Button>

                                <Button variant={"contained"} color={"error"} sx={{width: "100px"}}
                                        onClick={() => {
                                            closePopup()
                                            resetForm()
                                        }}>
                                    Cancelar
                                </Button>
                            </div>
                        </form>
                    </Popup>
                )}

                <TableContainer component={Paper}>
                    <Table aria-label="simple table" size={"small"}>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center"><b>Nome Aplicação</b></TableCell>
                                <TableCell align="center"><b>CNPJ</b></TableCell>
                                <TableCell align="center"><b>Ambiente</b></TableCell>
                                <TableCell align="center"><b>Token</b></TableCell>
                                <TableCell align="center"><b>Ações</b></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tokens.map((row) => (
                                <TableRow
                                    key={row.token}
                                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                >
                                    <TableCell align="center">{row.aplicacao.nome}</TableCell>
                                    <TableCell
                                        align="center">{row.parceiro.cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, '$1.$2.$3/$4-$5')}</TableCell>
                                    <TableCell
                                        align="center">{row.ambiente === 'H' ? "Homologação" : "Produção"}</TableCell>
                                    <TableCell align="center">{row.token}</TableCell>
                                    <TableCell align="center">
                                        <a href="javascript:void(0)">
                                            <img src={excluir} alt={"Excluir"} onClick={() => deleteToken(row)}/>
                                        </a>

                                        <a href="javascript:void(0)">
                                            <img src={edit} alt={"Editar"} onClick={() => {
                                                row.confSenha = row.senha
                                                row.confSenhaPlace = row.senhaPlace

                                                editToken(row)
                                            }}/>
                                        </a>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    )
}
