import api from "./apiService";
import {Token} from "../dto/Token";
import {Aplicacao} from "../dto/Aplicacao";

const parceiroService = {
    getAll: async (token: string) => {
        try {
            const response = await api.get('/parceiros', {
                params: {
                    token: token
                }
            });

            return response.data.responseBody;
        } catch (error) {
            throw error;
        }
    },
}

export default parceiroService