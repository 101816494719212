import api from "./apiService";
import {Token} from "../dto/Token";

const tokenService = {
    getTokens: async (token: string) => {
        try {
            const response = await api.get('/tokens', {
                params: {
                    token: token
                }
            });

            return response.data.responseBody;
        } catch (error) {
            throw error;
        }
    },
    saveToken: async (token: string, data: Token) => {
        try {
            const response = await api.post('/tokens',
                data,
                {
                    params: {
                        token: token
                    }
                });

            return response.data.responseBody;
        } catch (error) {
            throw error;
        }
    },
    deleteToken: async (token: string, data: Token) => {
        try {
            await api.delete('/tokens',
                {
                    params: {
                        token: token,
                        id: data.token
                    }
                });
        } catch (error) {
            throw error;
        }
    }
}

export default tokenService