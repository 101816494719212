import React, {useEffect} from "react";
import SideNavigation from "../components/SideNavigation";
import {Outlet, useLocation, useNavigate} from 'react-router-dom';

export default function HomePage() {

    const navigate = useNavigate()
    const location = useLocation();

    useEffect(() => {

        if (location.pathname === "/"){
            navigate("/aplicacoes")
        }
    }, []);


    return (
        <div className={"home-container"}>
            <SideNavigation/>

            <Outlet />
        </div>
    )
}