import {IsNotEmpty} from "class-validator";
import {Aplicacao} from "./Aplicacao";
import {Parceiro} from "./Parceiro";

export class Imei {

    @IsNotEmpty({message: "Informe o IMEI do dispositivo."})
    imei: string

    @IsNotEmpty({message: "Informe o parceiro."})
    codParc: number

    @IsNotEmpty({message: "Informe a aplicação."})
    codApp: number

    parceiro?: Parceiro

    aplicacao?: Aplicacao
}