import {IsEmail, IsNotEmpty, Length, MinLength} from "class-validator";
import {Match} from "../helpers/match.decorator";

export class Aplicacao {

    id: number

    @IsNotEmpty({message: "Informe o nome da aplicação."})
    nome: string

    @IsNotEmpty({message: "Informe a AppKey."})
    appKey: string
}