import {IsEmail, IsNotEmpty, Length, MaxLength, MinLength, ValidateIf} from "class-validator";
import {Match} from "../helpers/match.decorator";
import {Aplicacao} from "./Aplicacao";
import {Parceiro} from "./Parceiro";

export class Token {

    aplicacao: Aplicacao

    @IsNotEmpty({message: "Informe o ambiente."})
    ambiente: string

    @IsNotEmpty({message: "Informe o Token."})
    token: string

    parceiro: Parceiro

    @IsNotEmpty({message: "Informe a senha."})
    @MinLength(6, {message: "A senha deve conter ao menos 6 dígitos."})
    senha: string

    @Match("senha", {message: "As senhas devem ser iguais."})
    confSenha: string

    @IsEmail({}, {message: "Email do Sankhya Place inválido."})
    emailPlace: string

    @IsNotEmpty({message: "Informe a senha."})
    @MinLength(6, {message: "A senha deve conter ao menos 6 dígitos."})
    senhaPlace: string

    @Match("senhaPlace", {message: "As senhas devem ser iguais."})
    confSenhaPlace: string
}