import {createTheme} from "@mui/material/styles";

const theme = createTheme({
    palette: {
        primary: {
            main: "#66cc66",
            contrastText: '#fff',
        },
    },
    components: {
        MuiLink: {
            styleOverrides: {
                root: {
                    color: '#2e3c50',
                    textDecoration: 'underline',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    marginTop: "10px"
                }
            }
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    margin: 0
                }
            }
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    width: "100%"
                }
            }
        },
    },
});
export default theme