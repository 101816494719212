import api from "./apiService";

const imeiService = {
    getImeisPorApp: async (token: string) => {
        try {
            const response = await api.get('/imei/app', {
                params: {
                    token: token
                }
            });

            return response.data.responseBody;
        } catch (error) {
            throw error;
        }
    },
    save: async (token: string, data) => {
        try {
            const response = await api.post('/imei',
                data,
                {
                    params: {
                        token: token
                    }
                });

            return response.data.responseBody;
        } catch (error) {
            throw error;
        }
    },
    // deleteToken: async (token: string, data: Token) => {
    //     try {
    //         await api.delete('/tokens',
    //             {
    //                 params: {
    //                     token: token,
    //                     id: data.token
    //                 }
    //             });
    //     } catch (error) {
    //         throw error;
    //     }
    // }
}

export default imeiService