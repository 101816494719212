import React from 'react';
// @ts-ignore
import ic_close from "../img/ic_close.svg";


const Popup = ({children, width, height, showCloseBtn, onClose}) => {
    return (
        <div className="popup">
            <div className="popup-content" style={{width: width, height: height}}>
                {
                    showCloseBtn && (
                        <a href="javascript:void(0)"
                           style={{
                               alignSelf: "flex-end",
                               marginTop: "10px",
                               marginRight: "10px",
                           }}>
                            <img src={ic_close} alt={"Fechar"}
                                 onClick={onClose && (() => onClose())}
                                 style={{width: "32px",}}/>
                        </a>)
                }

                <div style={{padding: "10px 35px"}}>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default Popup;