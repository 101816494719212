import axios from "axios";

const api = axios.create({
    baseURL: 'https://app.sankhyamt.com.br/api',
    headers: {
        'Content-Type': 'application/json',
    },
});

api.interceptors.response.use((response) => {
    return response;
}, (error) => {

    if (error.response && error.response.status === 403) {
        window.location.pathname = "/login"
    }

    return Promise.reject(error);
});

export default api;