import React, {useEffect, useState} from "react";
import authService from "../services/authService";
import {showError} from "../helpers/toastr";
import imeiService from "../services/imeiService";
import {CardAplicacaoImei} from "../components/CardAplicacao";
import Popup from "../components/Popup";
import {
    Box, Button,
    Collapse, FormControl, FormHelperText,
    IconButton, InputLabel, LinearProgress, MenuItem,
    Paper, Select,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, TextField,
    Typography
} from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {Controller, useForm} from "react-hook-form";
import {classValidatorResolver} from "@hookform/resolvers/class-validator";
import {Imei} from "../dto/Imei";
import parceiroService from "../services/parceiroService";
import aplicacaoService from "../services/aplicacaoService";
import {Parceiro} from "../dto/Parceiro";
import {Aplicacao} from "../dto/Aplicacao";

export default function ImeisPage() {

    const [apps, setApps] = useState([])
    const [popListOpen, setPopupListOpen] = useState(false)
    const [parceiros, setParceiros] = useState([])
    const [loading, setLoading] = useState(true)
    const [popupFormOpen, setPopupFormOpen] = useState(false)
    const [cadParceiros, setCadParceiro] = useState([])
    const [cadApps, setCadApps] = useState([])

    useEffect(() => {
        loadData()
    }, [])

    const loadData = () => {
        setLoading(true)

        imeiService.getImeisPorApp(authService.getToken())
            .then(data => {
                setApps(data)
                setLoading(false)
            })
            .catch(error => {
                if (!error.response) {
                    showError(error.message)
                } else {
                    showError(error.response.data.errorMessage)
                }

                setLoading(false)
            })
    }
    const visualizarImeis = (app) => {
        setPopupListOpen(true)
        setParceiros(app.parceiros)
    }

    const closePopupList = () => {
        setPopupListOpen(false)
    }

    function Row(props: { row }) {
        const {row: {parceiro, imeis}} = props;
        const [open, setOpen] = React.useState(false);
        const [ativos, setAtivos] = React.useState({});

        useEffect(() => {
            const initialAtivos = {};
            imeis.forEach(imei => {
                initialAtivos[imei.imei] = imei.ativo === 'S';
            });
            setAtivos(initialAtivos);
        }, [imeis]);

        const toggleAtivo = (imei) => {
            const isAtivo = !ativos[imei.imei];
            imei.ativo = isAtivo ? "S" : "N";
            imei.dhAlteracao = undefined

            imeiService.save(authService.getToken(), imei)
                .then((data) => {
                    imei.dhAlteracao = data.dhAlteracao
                    setAtivos(prevAtivos => {
                        return {
                            ...prevAtivos,
                            [imei.imei]: !prevAtivos[imei.imei]
                        };
                    });
                })
                .catch(error => {
                    if (!error.response) {
                        showError(error.message)
                    } else {
                        showError(error.response.data.errorMessage)
                    }
                })
        }

        return (
            <React.Fragment>
                <TableRow sx={{'& > *': {borderBottom: 'unset'}}}>
                    <TableCell>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpen(!open)}
                        >
                            {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                        </IconButton>
                    </TableCell>
                    <TableCell align="left">{parceiro.id}</TableCell>
                    <TableCell>{parceiro.nomeParceiro}</TableCell>
                    <TableCell>{parceiro.cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, '$1.$2.$3/$4-$5')}</TableCell>
                    <TableCell align="right">{parceiro.qtdLicencas}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={6}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box sx={{margin: 1}}>
                                <Typography variant="h6" gutterBottom component="div">
                                    Telefones Liberados
                                </Typography>
                                <Table size="small" aria-label="purchases">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell><b>Id do Dispositivo</b></TableCell>
                                            <TableCell><b>Dt./Hr. Alteração</b></TableCell>
                                            <TableCell><b>Ativo</b></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {imeis.map(imei => {
                                            const ativo = ativos[imei.imei] || false;

                                            const handleChange = () => {
                                                toggleAtivo(imei);
                                            };

                                            return (
                                                <TableRow key={imei.imei}>
                                                    <TableCell component="th" scope="row">{imei.imei}</TableCell>
                                                    <TableCell>{imei.dhAlteracao}</TableCell>
                                                    <TableCell>
                                                        <Switch
                                                            checked={ativo}
                                                            onChange={handleChange}
                                                            inputProps={{'aria-label': 'controlled'}}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        );
    }

    const resolver = classValidatorResolver(Imei);

    const {
        handleSubmit,
        control,
        reset: resetForm,
        formState: {errors},
    } = useForm<Imei>({
        resolver: resolver,
    });

    const submitForm = async (data: Imei) => {

        setLoading(true)
        data.parceiro = new Parceiro()
        data.parceiro.id = data.codParc

        data.aplicacao = new Aplicacao()
        data.aplicacao.id = data.codApp

        imeiService.save(authService.getToken(), data)
            .then(() => {
                setPopupFormOpen(false)
                resetForm()
                loadData()
                setLoading(false)
            })
            .catch(error => {
                if (!error.response) {
                    showError(error.message)
                } else {
                    showError(error.response.data.errorMessage)
                }
                setLoading(false)
            })
    };

    const cadastrarDispositvo = async () => {
        setLoading(true);

        try {
            const parceiros = await parceiroService.getAll(authService.getToken());
            const apps = await aplicacaoService.getAll(authService.getToken());

            setCadParceiro(parceiros);
            setCadApps(apps);
            setLoading(false)
            setPopupFormOpen(true);
        } catch (error) {
            if (!error.response) {
                showError(error.message);
            } else {
                showError(error.response.data.errorMessage);
            }

            setLoading(false)
        }
    }

    return (
        <div style={{width: "100%"}}>
            {loading && <LinearProgress/>}

            <div className={"home-item"}>
                <Button variant={"contained"}
                        sx={{alignSelf: "flex-end", marginBottom: "10px", marginRight: "10px"}}
                        onClick={() => cadastrarDispositvo()}>
                    Novo Dispositivo
                </Button>

                {apps.map(app => (
                    <CardAplicacaoImei data={app} onClick={visualizarImeis}/>
                ))}

                {
                    popupFormOpen && (
                        <Popup width={"40%"} height={"60%"} showCloseBtn={false} onClose={undefined}>
                            <form onSubmit={handleSubmit(submitForm)}
                                  style={{display: "flex", flexDirection: "column", height: "100%"}}>
                                <h3>Dados do Dispositivo</h3>

                                <FormControl sx={{width: "100%", display: "flex"}}>
                                    <Controller
                                        name="imei"
                                        control={control}
                                        defaultValue=""
                                        render={({field}) => (
                                            <div className={"text-input-container"}>
                                                <TextField
                                                    {...field}
                                                    name="imei"
                                                    label={"Id do Dispositivo"}
                                                    type="text"
                                                    variant="filled"
                                                    autoComplete={"off"}
                                                />
                                                {errors.imei && (
                                                    <FormHelperText>
                                                        {errors.imei && (
                                                            <p className="form-helper-text">
                                                                {errors.imei.message}
                                                            </p>
                                                        )}
                                                    </FormHelperText>
                                                )}
                                            </div>
                                        )}
                                    />

                                    <Controller
                                        name="codParc"
                                        control={control}
                                        render={({field}) => (
                                            <div className={"text-input-container"}>
                                                <FormControl fullWidth variant="filled">
                                                    <InputLabel id="select-ambiente-label">Parceiro</InputLabel>

                                                    <Select
                                                        {...field}
                                                        labelId="select-parceiro-label"
                                                        label={"Parceiro"}
                                                        id="select-parceiro"
                                                        name={"codParc"}
                                                        sx={{width: "100%"}}>
                                                        {
                                                            cadParceiros.map(parc => {
                                                                return (
                                                                    <MenuItem value={parc.id}>{parc.nomeParceiro}</MenuItem>
                                                                )
                                                            })
                                                        }
                                                    </Select>
                                                    {errors.codParc && (
                                                        <FormHelperText>
                                                            {errors.codParc && (
                                                                <p className="form-helper-text">
                                                                    {errors.codParc.message}
                                                                </p>
                                                            )}
                                                        </FormHelperText>
                                                    )}
                                                </FormControl>
                                            </div>
                                        )}
                                    />

                                    <Controller
                                        name="codApp"
                                        control={control}
                                        render={({field}) => (
                                            <div className={"text-input-container"}>
                                                <FormControl fullWidth variant="filled">
                                                    <InputLabel id="select-ambiente-label">Aplicação</InputLabel>

                                                    <Select
                                                        {...field}
                                                        labelId="select-app-label"
                                                        label={"Aplicação"}
                                                        id="select-app"
                                                        name={"codApp"}
                                                        sx={{width: "100%"}}>
                                                        {
                                                            cadApps.map(app => {
                                                                return (
                                                                    <MenuItem value={app.id}>{app.nome}</MenuItem>
                                                                )
                                                            })
                                                        }
                                                    </Select>
                                                    {errors.codApp && (
                                                        <FormHelperText>
                                                            {errors.codApp && (
                                                                <p className="form-helper-text">
                                                                    {errors.codApp.message}
                                                                </p>
                                                            )}
                                                        </FormHelperText>
                                                    )}
                                                </FormControl>
                                            </div>
                                        )}
                                    />
                                </FormControl>

                                <div style={{alignSelf: "flex-end", marginTop: "auto"}}>
                                    <Button variant={"contained"} type={"submit"}
                                            sx={{width: "100px", marginRight: "10px"}}>
                                        Salvar
                                    </Button>

                                    <Button variant={"contained"} color={"error"} sx={{width: "100px"}}
                                            onClick={() => {
                                                setPopupFormOpen(false)
                                                resetForm()
                                            }}>
                                        Cancelar
                                    </Button>
                                </div>
                            </form>
                        </Popup>
                    )
                }

                {
                    popListOpen && (
                        <Popup width={"50%"} height={"70%"} showCloseBtn={true} onClose={() => closePopupList()}>
                            <TableContainer component={Paper}>
                                <Table aria-label="collapsible table" size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell/>
                                            <TableCell align="left"><b>Id</b></TableCell>
                                            <TableCell><b>Nome Parceiro</b></TableCell>
                                            <TableCell><b>CNPJ</b></TableCell>
                                            <TableCell align="right"><b>Qtd. de Licenças</b></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {parceiros.map((row) => (
                                            <Row key={row.name} row={row}/>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Popup>
                    )
                }
            </div>
        </div>
    )
}