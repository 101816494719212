import axios from "axios";
import {NovaConta} from "../dto/NovaConta";

const api = axios.create({
    baseURL: 'https://app.sankhyamt.com.br/api',
    headers: {
        'Content-Type': 'application/json',
    },
});

const authService = {
    login: async (username, password) => {
        try {
            const response = await api.post('/usuarios/autenticar',
                {
                    "usuario": username,
                    "senha": password
                });

            if (!response.data.responseBody) {
                throw new Error(response.data.errorMessage);
            } else {
                localStorage.setItem('token', response.data.responseBody.token);

                return response.data;
            }
        } catch (error) {
            throw error;
        }
    },

    logout: () => {
        // Remova o token do localStorage ao fazer logout
        localStorage.removeItem('token');
    },

    getToken: () => {
        // Obtenha o token do localStorage
        return localStorage.getItem('token');
    },

    isLoggedIn: () => {
        // Verifique se o token está presente no localStorage
        return !!localStorage.getItem('token');
    },

    createUser: async (data: NovaConta) => {
        try {
            const response = await api.post('/usuarios', data);

            if (!response.data.responseBody) {
                throw new Error(response.data.errorMessage);
            } else {
                localStorage.setItem('token', response.data.responseBody.token);

                return response.data;
            }
        } catch (error) {
            throw error;
        }
    },
};

export default authService;
