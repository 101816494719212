import {Button, FormControl, FormHelperText, Link, TextField} from "@mui/material";
import '../styles/Login.css'
import {Controller, useForm} from "react-hook-form";
import {classValidatorResolver} from "@hookform/resolvers/class-validator";
import React from "react";
import {Login} from "../dto/Login";
import {useNavigate} from "react-router-dom";
import authService from "../services/authService";
import {showError} from "../helpers/toastr";
import {handleMouseOver} from "../helpers/io";

export default function LoginPage() {

    const navigate = useNavigate()
    const resolver = classValidatorResolver(Login);

    const {
        handleSubmit,
        control,
        formState: {errors},
    } = useForm<Login>({
        resolver,
    });

    function navigateCriaConta() {
        window.location.pathname = "/criar-usuario"
    }

    const submitForm = async (data: Login) => {
        try {
            await authService.login(data.usuario, data.senha)

            navigate("/aplicacoes")
        } catch (error) {
            if (!error.response) {
                showError(error.message)
            } else {
                showError(error.response.data.errorMessage)
            }
        }
    };

    return (
        <div className={"main"}>
            <div className={"form-login-container"}>
                <h3 className={"label-login"}>Olá, seja bem vindo!</h3>

                <form onSubmit={handleSubmit(submitForm)} className={"form-conta"}>
                    <FormControl>
                        <Controller
                            name="usuario"
                            control={control}
                            defaultValue=""
                            render={({field}) => (
                                <div className={"text-input-container"}>
                                    <TextField
                                        {...field}
                                        name="usuario"
                                        label={"Usuario"}
                                        type="text"
                                        variant="filled"
                                    />
                                    {errors.usuario && (
                                        <FormHelperText>
                                            {errors.usuario && (
                                                <p className="form-helper-text">
                                                    {errors.usuario.message}
                                                </p>
                                            )}
                                        </FormHelperText>
                                    )}
                                </div>
                            )}
                        />

                        <Controller
                            name="senha"
                            control={control}
                            defaultValue=""
                            render={({field}) => (
                                <div className={"text-input-container"}>
                                    <TextField
                                        {...field}
                                        name="senha"
                                        label={"Senha"}
                                        type="password"
                                        variant="filled"
                                    />
                                    {errors.senha && (
                                        <FormHelperText>
                                            {errors.senha && (
                                                <p className="form-helper-text">
                                                    {errors.senha.message}
                                                </p>
                                            )}
                                        </FormHelperText>
                                    )}
                                </div>
                            )}
                        />
                    </FormControl>

                    <Button type={"submit"} variant="contained">
                        Entrar
                    </Button>

                    <div className={"link-criar-conta"}>
                        <p onMouseOver={handleMouseOver} onClick={navigateCriaConta} className={"link-criar-conta"}>
                            Criar Conta
                        </p>
                    </div>
                </form>
            </div>
        </div>
    )
}
