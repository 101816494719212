import React, {useState} from "react";
import {Link} from 'react-router-dom';

// @ts-ignore
import vpn_key from "../img/vpn_key.svg"
// @ts-ignore
import app_registration from "../img/app_registration.svg"
// @ts-ignore
import cellphone from "../img/cellphone_.svg"

export default function SideNavigation() {

    function LinkNav({path, name, img}) {

        return (
            <Link to={path}
                  style={{
                      textDecoration: "none",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      color: '#2e3c50',
                      padding: 5
                  }}>
                <img src={img} alt={name} className={"side-nav-img"}/>

                {name}
            </Link>
        )
    }

    return (
        <div className={"side-nav"}>
            <div style={{marginTop: '5px'}}>
                <LinkNav path={"/aplicacoes"} name={"Aplicações"} img={app_registration}/>
            </div>

            <div style={{marginTop: '5px'}}>
                <LinkNav path={"/tokens"} name={"Tokens"} img={vpn_key}/>
            </div>

            <div style={{marginTop: '5px'}}>
                <LinkNav path={"/imei"} name={"Dispositivos"} img={cellphone}/>
            </div>
        </div>
    )
}