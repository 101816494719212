import api from "./apiService";
import {Aplicacao} from "../dto/Aplicacao";

const tokenService = {
    getAll: async (token: string) => {
        try {
            const response = await api.get('/aplicacoes', {
                params: {
                    token: token
                }
            });

            return response.data.responseBody;
        } catch (error) {
            throw error;
        }
    },
    saveApp: async (token: string, data: Aplicacao) => {
        try {
            const response = await api.post('/aplicacoes',
                data,
                {
                    params: {
                        token: token
                    }
                });

            return response.data.responseBody;
        } catch (error) {
            throw error;
        }
    },
    deleteApp: async (token: string, data: Aplicacao) => {
        try {
            await api.delete('/aplicacoes',
                {
                    params: {
                        token: token,
                        id: data.id
                    }
                });
        } catch (error) {
            throw error;
        }
    }
}

export default tokenService