import {Navigate} from "react-router-dom";
import authService from "../services/authService";

const ProtectedRoute = ({user, children}) => {
    if (!authService.isLoggedIn()) {
        return <Navigate to="/login" replace/>;
    }

    return children;
};

export default ProtectedRoute;